import { KrowCollection, KrowDocument, KrowResource } from 'store/industry'
import { ListQueryParams } from 'store/store.types'
import { api } from '../store.api'

export type TStatefulResource =
  | 'positions'
  | 'alerts'
  | 'interviews'
  | 'questionnaires'
  | 'applicants'
  | 'exports'
  | 'organization_settings'
  | 'landing_pages'

export type TStatefulEntityTypes = 'Position' | 'Applicant'

export type TStateAttributes = {
  name: string
  description: number
  entity_type: TStatefulResource
  created_at: string
  updated_at: string
}

export type TStateDocument = KrowDocument<'state', TStateAttributes>
export type TStateResource = KrowResource<'state', TStateAttributes>
export type TStateCollection = KrowCollection<'state', TStateAttributes>

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    listStates: build.query<TStateCollection, ListQueryParams>({
      query: (params) => {
        return {
          method: 'GET',
          url: `/states`,
          params: { ...params },
        }
      },
    }),
    currentState: build.query<TStateResource, { type: TStatefulResource; id: string }>({
      query: ({ type, id }) => {
        return {
          method: 'GET',
          url: `${type}/${id}/state`,
        }
      },
    }),
    listTransitions: build.query<TStateCollection, string>({
      query: (id) => {
        return {
          method: 'GET',
          url: `states/${id}/transitions`,
        }
      },
    }),
    updateState: build.mutation<
      TStateResource,
      { type: TStatefulResource; id: string; stateId: string }
    >({
      query: ({ type, id, stateId }) => {
        return {
          method: 'PUT',
          url: `${type}/${id}/state/${stateId}`,
        }
      },
    }),
  }),
})

export const {
  useListStatesQuery,
  useCurrentStateQuery,
  useListTransitionsQuery,
  useUpdateStateMutation,
} = extendedApi
